<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-dialog
          v-model="dialogRegistros"
          width="1200"
          persistent
        >
          <v-card>
            <v-card-title class="text-h3 grey lighten-2">
              Administración de juzgados
            </v-card-title>

            <v-card-text>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="departamentoJuzgado"
                    label="Departamento"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="provinciaJuzgado"
                    label="Provincia"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="distritoJuzgado"
                    label="Distrito"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="nombreJuzgado"
                    label="Nombre"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="direccionJuzgado"
                    label="Direccion"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="horarioJuzgado"
                    label="Horario"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="juezJuzgado"
                    label="Juez"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="celularJuzgado"
                    label="Celular"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-text-field
                    v-model="latitudJuzgado"
                    label="Latitud"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-text-field
                    v-model="longitudJuzgado"
                    label="Longitud"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  v-if="actualizar"
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="estadoJuzgado"
                    :items="itemsEstados"
                    item-text="nombre"
                    item-value="id"
                    label="Estado"
                    :rules="regla"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="cerrarDialog()"
              >
                Cerrar
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="registrarJuzgado()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </div>
    <v-row v-if="!carga">
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          icon="mdi-format-list-numbered"
          color="primary"
          title="Administración de juzgados"
        >
          <v-card-title>
            <v-btn
              v-if="dataUsuario.tipo_usuario === 'E'"
              class="ma-5 md-round"
              rounded
              medium
              color="green"
              @click="abrirDialog()"
            >
              <v-icon>
                mdi-plus-box-outline
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Buscar..."
              hide-details
              single-line
              style="max-width: 250px;"
            />
          </v-card-title>

          <v-data-table
            :headers="headersT"
            :items="items"
            :search="search"
            dense
            class="elevation-1"
            fixed-header
            :loading="carga"
            loading-text="Cargando, por favor espere"
            :footer-props="{
              'items-per-page-options': [25, 50, -1],
              'items-per-page-text': 'Mostrando:',
              'items-per-page-all-text': 'Todos'
            }"
            no-data-text="No hay datos registrados"
            no-results-text="No se encontraron resultados"
          >
            <template
              v-if="dataUsuario.tipo_usuario === 'E'"
              #[`item.editar`]="{ item }"
            >
              <v-btn
                color="green"
                fab
                x-small
                dark
                @click="editarRegistro(item)"
              >
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </template>
            <template #[`item.ubicacion`]="{ item }">
              <v-btn
                v-if="item.lat && item.lon"
                color="blue"
                fab
                x-small
                dark
                @click="abrirMaps(item)"
              >
                <v-icon>mdi-map-marker</v-icon>
              </v-btn>
            </template>
            <template #[`item.estado`]="{ item }">
              <v-icon
                v-if="item.estado === 1"
                color="green"
              >
                mdi-check-decagram
              </v-icon>
              <v-icon
                v-else
                color="red"
              >
                mdi-close-circle
              </v-icon>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'AdministracionJuzgados',
    data () {
      return {
        headersT: [
          {
            text: 'Editar',
            value: 'editar',
          },
          {
            text: '#',
            value: 'id_juzgado',
          },
          {
            text: 'Provincia',
            value: 'provincia',
          },
          {
            text: 'Distrito',
            value: 'distrito',
          },
          {
            text: 'Juzgado',
            value: 'nombre',
          },
          {
            text: 'Dirección',
            value: 'direccion',
          },
          {
            text: 'Horario',
            value: 'horario',
          },
          {
            text: 'Ubicación',
            value: 'ubicacion',
          },
          {
            text: 'Juez',
            value: 'juez',
          },
          {
            text: 'Celular',
            value: 'celular_juez',
          },
          {
            text: 'Estado',
            value: 'estado',
          },
        ],
        itemsEstados: [
          { id: 1, nombre: 'ACTIVO' },
          { id: 0, nombre: 'DESACTIVADO' },
        ],
        items: [],
        carga: true,
        dialogRegistros: false,
        search: '',
        actualizar: false,
        estadoSeleccionado: '',
        idSeleccionado: '',
        urlUsuario: '',
        regla: [(v) => !!v || 'Campo requerido'],
        itemsJuzgados: [],
        juzgadoSeleccionado: '',
        headers: { headers: { 'access-token': localStorage.getItem('tokenOdajup') } },
        departamentoJuzgado: '',
        provinciaJuzgado: '',
        distritoJuzgado: '',
        nombreJuzgado: '',
        direccionJuzgado: '',
        horarioJuzgado: '',
        juezJuzgado: '',
        celularJuzgado: '',
        latitudJuzgado: '',
        longitudJuzgado: '',
        estadoJuzgado: '',
      }
    },
    created () {
      // const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
      this.imgLogo = 'assets/favicon.png'
      const data = {
        usuario: 1,
        contrasena: 1,
      }
      axios
        .post(`${localStorage.getItem('urlApiOdajup')}/login`, data)
        .then((r) => {
          console.log(r)
          if (r.data.success === true) {
            localStorage.setItem('usuarioOdajup', JSON.stringify(r.data.data[0]))
            localStorage.setItem('tokenOdajup', r.data.token)
            this.carga = false
          } else {
            this.carga = false
            this.error = true
            this.textoError = 'Usuario o contraseña incorrecta'
          }
        })
        .catch((err) => {
          alert('Servicio no disponible' + err)
        })
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioOdajup'))
      console.log(this.dataUsuario)
      this.imgLogo = 'assets/favicon.png'
      if (this.dataUsuario.tipo_usuario !== 'E') {
        this.$store.commit('SET_VALOR', true)
      }
      this.validarToken()
    },
    methods: {
      validarToken () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/token`, this.headers)
          .then((r) => {
            if (r.data.message !== true) {
              this.$router.replace('/cerrar')
            } else {
              this.leerJuzgados()
              this.carga = false
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
            this.carga = false
            this.$router.replace('/cerrar')
          })
      },
      abrirDialog () {
        this.dialogRegistros = true
        this.juzgadoSeleccionado = this.dataUsuario.id_juzgado
      },
      abrirMaps (item) {
        var link = document.createElement('a')
        link.download = name
        link.href = 'https://www.google.com/maps/search/' + item.lat + ',+' + item.lon
        link.setAttribute('target', '_blank')
        link.click()
        link.remove()
      },
      leerJuzgados () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/juzgados/`, this.headers)
          .then((response) => {
            this.items = response.data.data
            this.carga = false
            this.cargaPartes = false
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      editarRegistro (item) {
        this.juzgadoSeleccionado = item
        this.departamentoJuzgado = item.departamento
        this.provinciaJuzgado = item.provincia
        this.distritoJuzgado = item.distrito
        this.nombreJuzgado = item.nombre
        this.direccionJuzgado = item.direccion
        this.horarioJuzgado = item.horario
        this.juezJuzgado = item.juez
        this.celularJuzgado = item.celular_juez
        this.latitudJuzgado = item.lat
        this.longitudJuzgado = item.lon
        this.estadoJuzgado = this.itemsEstados.filter(tipo => tipo.id === item.estado)[0]
        this.idSeleccionado = item.id_juzgado
        this.actualizar = true
        this.dialogRegistros = true
      },
      cerrarDialog () {
        this.tipoRegistro = null
        this.actualizar = false
        this.dialogRegistros = false
        this.$refs.form.reset()
      },
      registrarJuzgado () {
        const data = {
          departamento: this.departamentoJuzgado,
          provincia: this.provinciaJuzgado,
          distrito: this.distritoJuzgado,
          nombre: this.nombreJuzgado,
          direccion: this.direccionJuzgado,
          horario: this.horarioJuzgado,
          juez: this.juezJuzgado,
          celular_juez: this.celularJuzgado,
          lat: this.latitudJuzgado,
          lon: this.longitudJuzgado,
          estado: this.estadoJuzgado.id,
          id_juzgado: this.idSeleccionado,
        }
        console.log(data)
        if (this.$refs.form.validate()) {
          this.actualizar === false ? this.loadingText = 'REGISTRANDO JUZGADO...' : this.loadingText = 'ACTUALIZANDO JUZGADO...'
          this.carga = true
          if (this.actualizar) {
            console.log('actualizando')
            axios
              .put(`${localStorage.getItem('urlApiOdajup')}v0/juzgado/`, data, this.headers)
              .then((r) => {
                console.log(r)
                if (r.data.message === true) {
                  alert('El juzgado se actualizó correctamente.')
                  this.carga = false
                  this.leerJuzgados()
                  this.cerrarDialog()
                } else {
                  alert('No se pudo completar la actualización.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          } else {
            axios
              .post(`${localStorage.getItem('urlApiOdajup')}v0/juzgado/`, data, this.headers)
              .then((r) => {
                console.log(r)
                if (r.data.message === true) {
                  alert('El juzgado se registró correctamente.')
                  this.carga = false
                  this.leerJuzgados()
                  this.cerrarDialog()
                } else {
                  alert('No se pudo completar el registro.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          }
        }
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
